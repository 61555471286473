import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { FilterButton } from '../filter-button/FilterButton'
import '@obeta/assets/theme/swiper.scss'
import styles from './TabFilters.module.scss'
import { TabOption } from '@obeta/models/lib/models/UI'

interface ITabFiltersProps<T> {
  options: TabOption<T>[]
  onChangeTab: (value: T) => void
  activeTab: T
  spaceBetween?: number
}

export const TabFilters = <T extends string | number>({
  options,
  onChangeTab,
  activeTab,
  spaceBetween = 16,
}: ITabFiltersProps<T>) => {
  const { desktop } = useBreakpoints()

  const [watchOverflow, setWatchOverflow] = useState(desktop)

  useEffect(() => {
    if (watchOverflow !== desktop) setWatchOverflow(desktop)
  }, [desktop, watchOverflow])

  return (
    <Swiper
      // To update swiper not enough just update it's watchOverflow prop, we need to update key as well
      key={String(watchOverflow)}
      // disable swipe effect
      watchOverflow={watchOverflow}
      direction="horizontal"
      slidesPerView={'auto'}
      className={styles.root}
      scrollbar={false}
      spaceBetween={spaceBetween}
      resizeObserver={true}
    >
      {options.map((tabFilter) => (
        <SwiperSlide key={tabFilter.value} className={styles.crumb}>
          <FilterButton
            icon={tabFilter.icon || null}
            active={activeTab === tabFilter.value && !tabFilter.disabled}
            disabled={tabFilter.disabled}
            onClick={() => onChangeTab(tabFilter.value)}
          >
            {tabFilter.label} {tabFilter.total !== undefined && '(' + tabFilter.total + ')'}
          </FilterButton>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
